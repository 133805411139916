import { FiPhone, FiMail } from "../assets/icons/vander";
import { FaTwitter, FaInstagram, FaCommentDots } from "react-icons/fa"; // FaCommentDots for social media interaction

export const contactData = [
    {
        icon: FiPhone,
        title: "Phone",
        desc: "Get started with PreCheck Hire, the platform where domestic workers can easily upload their profiles and find employment.",
        link: "tel:+234-123-456-7890",
    },
    {
        icon: FiMail,
        title: "Email",
        desc: "Get started with PreCheck Hire, the platform where domestic workers can easily upload their profiles and find employment.",
        link: "mailto:support@precheckhire.com",
    },
];

export const socialMediaData = {
    title: "Social Media",
    icon: FaCommentDots,  // Representing communication and social media interaction
    links: [
        { icon: FaTwitter, link: "http://twitter.com/precheckhire" },
        { icon: FaInstagram, link: "https://www.instagram.com/pre_checkhire" },
    ],
};
